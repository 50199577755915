<template>
  <div class="container">
    <div class="index_header">
      <div class="current_city">
        {{ cityInfo.cityName }}
      </div>
      <div class="operate mb18">
        <div class="left">当前城市</div>
        <div class="right">
          <span class="gps">GPS定位</span>
          <span class="city ml8 mr8">{{ cityInfo.cityName }}</span>
          <span class="icon">
            <img
              @click="relocation"
              :src="require('@img/city.png')"
              class="icon_reset"
            />
          </span>
        </div>
      </div>
    </div>

    <div class="index_bar">
      <IndexBar :index-list="indexList">
        <div v-for="model in indexList" :key="model">
          <IndexAnchor :index="model">
            {{ model }}
          </IndexAnchor>
          <Cell
            @click="selectedCity(item)"
            v-for="item in citys[model]"
            :key="item.cityId"
            :title="item.cityName"
          />
        </div>
      </IndexBar>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import { IndexBar, IndexAnchor, Cell } from 'vant';
import { mapState, mapMutations, mapActions } from 'vuex';
import Configs from '@/config';

export default {
  components: {
    IndexBar,
    IndexAnchor,
    Cell,
  },
  data () {
    return {
      indexList: [],
      citys: {},
    };
  },
  computed: {
    ...mapState({
      cityInfo: (store) => store.city.cityInfo,
    }),
  },
  created () {
    this.getAllCitys();
  },
  methods: {
    ...mapMutations('city', [
      'updateCityInfo',
    ]),
    ...mapActions('city', [
      'getCurrentCityInfo',
    ]),
    selectedCity (city) {
      this.updateCityInfo({ ...city });
      this.$router.back();
    },
    relocation () {
      this.getCurrentCityInfo(() => {
        this.$router.back();
      });
    },
    async getAllCitys () {
      let result;
      const channelCode = localStorage.getItem('channelCode')
      if (channelCode === "PAK") { // 派安康来源
        result = await api.getCitys({ saleChannel: Configs.channelCode[channelCode.toUpperCase()] });
      } else {
        result = await api.getCitys();
      }
      const citys = result.data || [];
      const obj = {};
      citys.forEach((item) => {
        if (!obj[item.key]) {
          obj[item.key] = [];
        }
        const {
          name: cityName, provincesId, cityId, standardCode: cityCode,
        } = item;
        obj[item.key].push({
          cityName, provincesId, cityId, cityCode,
        });
      });
      this.indexList = Object.keys(obj).sort();
      this.citys = { ...obj };
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
